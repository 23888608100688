.listing-card {
    height: 220px;
    border: 1px solid #CCCCCC;
    width: auto;
    margin: 16px;
    border-radius: 10px;
    
    display: flex;
    flex-direction: row;
    background-image: linear-gradient(to right, #111111, #444444); /* Main color */
    box-shadow: 0px 0px 20px 20px rgba(0, 0, 0, 0.1 ); /* Subtle shadow effect */
}

.listing-image {
    height: 220px;
    border-radius: 9px 0px 0px 9px;
    aspect-ratio: 16/9;
}

.listing-card:hover {
    background-color: #EFEFEF;
    box-shadow: 0px 0px 5px #CCCCCC;
    
}

.listing-details {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 20px
}

.listing-info h2 {
    margin: 0;
}

.listing-info p {
    margin: 3px 0px;
}



.listing-name {
    font-size: 36px;
}

.listing-info{
    font-family: sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: space-between
}

.listing-price {
    font-size: 44px;
}

.bottom-row {
    display: flex;
    justify-content: space-between;
    margin: 6px 0px;
}