

.site-name {
  color: rgb(142, 168, 195);
  font-size: 3rem;
  font-Weight: 600;
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  text-decoration: none;
  padding: 1rem
}

.middle-link {
  color: #F9F8F8;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
}

.middle-link:hover {
  color:rgb(142, 168, 195);
}


.button-link {
  border-radius: 1rem;
  padding: 1rem;
  text-decoration: none;
  border: 3px solid rgb(142, 168, 195);
  color: rgb(142, 168, 195);
  margin: 16px;
  margin-left: 0px;
  font-size: 1.25rem;
  font-weight: 800;
}

.button-link:hover {
  color: #14213D;
  background-color: rgb(142, 168, 195);;
}

.row {
  list-style: none; /* Remove default list bullets */
  display: flex; /* Use a flex container */
  padding: 0; /* Remove default list padding */
}

.user-name {
  background: none;
  border: none;
  font-weight: 600;
  color: rgb(142, 168, 195);
  box-shadow: none;
}

.User-row {
  color: rgb(142, 168, 195);
  display: flex;
  flex-direction: row;
  width: fit-content;
  justify-content: center;
  align-items:center;
  border: 2px solid rgb(142, 168, 195);
  padding: 0.2rem 6px;
  border-radius: 1rem;
}

.User-row:hover {
  color: #14213d;
  background-color: rgb(142, 168, 195)
}

.User-row:hover button {
  color:#14213D !important
}